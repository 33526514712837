import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { CgSpinner } from 'react-icons/cg';
import { FiDownload, FiEdit, FiFile, FiTrash2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionButton, SearchInput } from '../../../../../components';
import { DeleteBudgetModal } from '../../../../../components/modal/delete-budget';
import { DisableBudgetModal } from '../../../../../components/modal/disable-budget';
import { UpdateBudgetPriceModal } from '../../../../../components/modal/update-budget-price';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../../components/table';
import InnerActions from '../../../../../components/table/innerActions';
import { useAuth } from '../../../../../contexts/auth';
import { Budget } from '../../../../../contexts/budgets/types';
import productsApi from '../../../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../../../types/pagination';
import { UserRoles } from '../../../../../types/users';
import { downloadFile } from '../../../../../utils/downloadFile';
import { Status, Tag } from '../tag';
import { Container, Header, TableHeader } from './styles';

type Props = {
  handleLoading: (isLoading: boolean) => void;
};

type BudgetTableItems = {
  customer: string;
  franchise: string;
  createdAt: string;
  actions?: JSX.Element;
};

type Modals = {
  delete: string | null;
  disable: string | null;
  updatePrice: Budget | null;
};

type PDF = {
  id: string;
  url: string | null;
  loading: boolean;
};

enum FINANCING_STATUS {
  'Financiamento aprovado' = 'Aprovado',
  'Financiamento recusado' = 'Recusado',
  'Financiamento pendente' = 'Pendente',
}

const FINANCING_STATUS_BY_INDEX: Record<number, keyof typeof FINANCING_STATUS> = {
  0: 'Financiamento aprovado',
  1: 'Financiamento recusado',
  2: 'Financiamento pendente',
};

export const Budgets: React.FC<Props> = (props) => {
  const { handleLoading } = props;

  const history = useHistory();

  const { selectedFranchise, user, franchises, selectFranchise } = useAuth();

  const isAdmin = UserRoles.ADMIN.includes(user.role);

  const isSuccessConsultant = UserRoles.CONSULTOR_SUCESSO.includes(user.role);

  const franchiseId = isAdmin
    ? selectedFranchise || null
    : isSuccessConsultant
    ? selectedFranchise || null
    : selectedFranchise || '';

  const [modals, setModals] = useState({
    delete: null,
    disable: null,
    updatePrice: null,
  } as Modals);

  const [budgets, setBudgets] = useState<PaginatedResponse<Budget>>(EMPTY_PAGE);

  const [search, setSearch] = useState('');

  const [searchDisabled, setSearchDisabled] = useState('');

  const [budgetsDisabled, setBudgetsDisabled] =
    useState<PaginatedResponse<Budget>>(EMPTY_PAGE);

  const debouncedFunc = debounce(
    async (
      page,
      limit,
      search,
      fn: (page?: number, limit?: number, search?: string) => Promise<void>
    ) => await fn(page, limit, search),
    350
  );

  const handleEditBudget = (budget: Budget) => {
    const currentBudgetFranchise = franchises.find(
      (franchise) => franchise.id === budget.franchiseId
    );

    if (currentBudgetFranchise) {
      selectFranchise(currentBudgetFranchise.id);

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${budget.id}/selecao-de-kits/editar`
      );

      return;
    }

    toast.info(
      'Você precisa estar vinculado a franquia desse orçamento para editá-lo.'
    );
  };

  const getBudgets = useCallback(
    async (page?: number, limit?: number, search?: string) => {
      handleLoading(true);

      setBudgets((state) => {
        return {
          ...state,
          loading: true,
        };
      });

      try {
        const { data } = await productsApi.get('/budgets/', {
          params: {
            'filters[status]': 'Completo',
            'filters[franchiseId]': franchiseId,
            'filters[name]': search || null,
            page: page || budgets.pagination.currentPage,
            limit: limit || budgets.pagination.limit,
          },
        });

        setBudgets(data);
      } catch (error) {
        const errorMessage = (error as AxiosError).response?.data.message;

        if (!selectedFranchise && !isAdmin && !isSuccessConsultant) {
          toast.error('Selecione uma franquia', {
            toastId: '1',
          });
        } else {
          toast.error(errorMessage);
        }

        throw error;
      } finally {
        handleLoading(false);

        setBudgets((state) => {
          return {
            ...state,
            loading: false,
          };
        });
      }
    },
    [budgets, selectedFranchise]
  );

  const getBudgetsDisabled = useCallback(
    async (page?: number, limit?: number, search?: string) => {
      setBudgetsDisabled((state) => {
        return {
          ...state,
          loading: true,
        };
      });
      try {
        const { data } = await productsApi.get<PaginatedResponse<Budget>>(
          '/budgets/',
          {
            params: {
              'filters[status]': 'Cancelado',
              'filters[franchiseId]': franchiseId,
              page: page || budgetsDisabled.pagination.currentPage,
              limit: limit || budgetsDisabled.pagination.limit,
              'filters[name]': search || null,
            },
          }
        );

        setBudgetsDisabled(data);
      } catch (error) {
        const errorMessage = (error as AxiosError).response?.data.message;

        if (!selectedFranchise && !isAdmin && !isSuccessConsultant) {
          toast.error('Selecione uma franquia', {
            toastId: '1',
          });
        } else {
          toast.error(errorMessage);
        }

        throw error;
      } finally {
        handleLoading(false);

        setBudgetsDisabled((state) => {
          return {
            ...state,
            loading: false,
          };
        });
      }
    },
    [budgetsDisabled, selectedFranchise]
  );

  const handleOrderBy = (columnId: string) => {
    setOrderBy((state) => {
      const [column, order] = state.split(',');

      if (column !== columnId) {
        return `${columnId},ASC`;
      }

      return order === `ASC` ? `${columnId},DESC` : state;
    });
  };

  const [orderBy, setOrderBy] = useState('');

  const budgetsTableColumns: ColumnStructure[] = useMemo(() => {
    const [columnId, order] = orderBy.split(',');

    return [
      {
        id: 'customer',
        label: 'Cliente',
      },
      {
        id: 'franchise',
        label: 'Franquia',
      },
      {
        id: 'createdAt',
        label: 'Data',
      },
      {
        id: 'updatedAt',
        label: 'Última edição',
      },
      {
        id: 'financing-bv',
        label: "Financiamento BV",
        isCentered: true,
      },
      {
        id: 'financing-btg',
        label: "Financiamento BTG",
        isCentered: true,
      },
      {
        id: 'actions',
        label: '',
        type: 'actionCell',
        isCentered: true,
      },
    ];
  }, [orderBy]);

  const budgetsDisabledTableColumns: ColumnStructure[] = [
    {
      id: 'customer',
      label: 'Cliente',
      onClick: () => {},
    },
    {
      id: 'franchise',
      label: 'Franquia',
    },
    {
      id: 'createdAt',
      label: 'Data',
      onClick: () => {},
    },
    {
      id: 'financing-bv',
      label: "Financiamento BV",
      isCentered: true,
    },
    {
      id: 'financing-btg',
      label: "Financiamento BTG",
      isCentered: true,
    },
  ];

  const [pdfs, setPdfs] = useState<PDF[]>([]);

  const downloadProposal = async (budgetId: string) => {
    const existingPdfIndex = pdfs.findIndex((pdf) => pdf.id === budgetId);

    const updatedPdf = {
      id: budgetId,
      url: null,
      loading: true,
    };

    if (existingPdfIndex !== -1) {
      const newPdfs = [...pdfs];

      newPdfs[existingPdfIndex] = updatedPdf;

      setPdfs(newPdfs);
    } else {
      setPdfs((state) => [...state, updatedPdf]);
    }

    try {
      const response = await productsApi.get(`/budgets/${budgetId}/proposal`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });

      const url = URL.createObjectURL(blob);

      downloadFile(url);
    } catch (error) {
      toast.error('Não foi possível baixar o arquivo');

      setPdfs(pdfs.filter((pdf) => pdf.id !== budgetId));
    } finally {
      setPdfs((state) =>
        state.map((pdf) =>
          pdf.id === budgetId
            ? {
                ...pdf,
                loading: false,
              }
            : pdf
        )
      );
    }
  };

  const budgetsTableItems: TableData<BudgetTableItems>[] = useMemo(() => {
    return budgets.content.map((budget, index) => {
      const currentBudgetPDF = pdfs.find((pdf) => pdf.id === budget.id);

      const financingStatus = FINANCING_STATUS_BY_INDEX[index % 3];

      return {
        id: budget.id,
        customer: budget.customer.name,
        franchise: budget.franchise.name,
        createdAt: format(new Date(budget.createdAt), 'dd/MM/yyyy'),
        updatedAt: format(new Date(budget.updatedAt), 'dd/MM/yyyy'),
        'financing-bv': <Tag status={financingStatus}>{FINANCING_STATUS[financingStatus]}</Tag>,
        'financing-btg': <Tag status={financingStatus}>{FINANCING_STATUS[financingStatus]}</Tag>,
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Gerar pedido"
              onClick={() =>
                history.push(
                  '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione/',
                  budget.id
                )
              }
            >
              <FiFile />
            </ActionButton>

            <ActionButton
              tooltip="Editar orçamento"
              onClick={() => handleEditBudget(budget)}
            >
              <FiEdit />
            </ActionButton>

            <ActionButton
              tooltip="Baixar proposta"
              onClick={() => downloadProposal(budget.id)}
              disabled={currentBudgetPDF?.loading}
            >
              {currentBudgetPDF?.loading ? (
                <CgSpinner className="proposal-loading" />
              ) : (
                <FiDownload />
              )}
            </ActionButton>

            <ActionButton
              tooltip="Desativar"
              onClick={() =>
                setModals((state) => {
                  return {
                    ...state,
                    disable: budget.id,
                  };
                })
              }
            >
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [budgets, selectedFranchise, pdfs]);

  const budgetsDisabledTableItems: TableData<BudgetTableItems>[] =
    useMemo(() => {
      return budgetsDisabled.content.map((budget, index) => {
        const financingStatus = FINANCING_STATUS_BY_INDEX[index % 3];

        return {
          id: budget.id,
          customer: budget.customer.name,
          franchise: budget.franchise.name,
          createdAt: format(new Date(budget.createdAt), 'dd/MM/yyyy'),
          updatedAt: format(new Date(budget.updatedAt), 'dd/MM/yyyy'),
          'financing-bv': <Tag status={financingStatus}>{FINANCING_STATUS[financingStatus]}</Tag>,
          'financing-btg': <Tag status={financingStatus}>{FINANCING_STATUS[financingStatus]}</Tag>,
        };
      });
    }, [budgetsDisabled, selectedFranchise]);

  const handleDisableBudget = () => {
    productsApi
      .patch(`/budgets/${modals.disable}`, {
        status: 'Cancelado',
      })
      .then(() => {
        toast.success('Orçamento desativado');

        getBudgets(budgets.pagination.currentPage, budgets.pagination.limit);

        getBudgetsDisabled(
          budgetsDisabled.pagination.currentPage,
          budgetsDisabled.pagination.limit
        );
      })
      .catch(() => {
        toast.error('Não foi possível desativar esse orçamento');
      })
      .finally(() => {
        setModals((state) => {
          return {
            ...state,
            disable: null,
          };
        });
      });
  };

  const handleDeleteBudget = () => {};

  useEffect(() => {
    getBudgets();
    getBudgetsDisabled();
  }, [selectedFranchise]);

  useEffect(() => {
    return () => {
      pdfs.forEach((pdfFile) => {
        if (pdfFile.url) {
          URL.revokeObjectURL(pdfFile.url);
        }
      });
    };
  }, [pdfs]);

  return (
    <Container>
      <div>
        <Header>
          <h1>Orçamentos em aberto</h1>
        </Header>
        <Table
          header={
            <TableHeader>
              <SearchInput
                placeholder="Digite aqui para pesquisar pelo nome do cliente"
                onTextChange={(search) => {
                  debouncedFunc(
                    1,
                    budgets.pagination.limit,
                    search,
                    getBudgets
                  );

                  setSearch(search);
                }}
                name="search"
              />
            </TableHeader>
          }
          isLoading={budgets.loading}
          items={budgetsTableItems}
          columns={budgetsTableColumns}
          placeholder="Nenhum orçamento encontrado"
          pagination={budgets.pagination}
          onPageChange={(page) =>
            getBudgets(page, budgets.pagination.limit, search)
          }
          onLimitChange={(limit) =>
            getBudgets(budgets.pagination.currentPage, limit, search)
          }
        />
      </div>

      <div>
        <Header>
          <h1>Orçamentos desativados</h1>
        </Header>
        <Table
          header={
            <TableHeader>
              <SearchInput
                placeholder="Digite aqui para pesquisar pelo nome do cliente"
                onTextChange={(search) => {
                  debouncedFunc(
                    1,
                    budgets.pagination.limit,
                    search,
                    getBudgetsDisabled
                  );

                  setSearchDisabled(search);
                }}
                name="search"
              />
            </TableHeader>
          }
          isLoading={budgetsDisabled.loading}
          items={budgetsDisabledTableItems}
          columns={budgetsDisabledTableColumns}
          placeholder="Nenhum orçamento encontrado"
          pagination={budgetsDisabled.pagination}
          onPageChange={(page) =>
            getBudgetsDisabled(
              page,
              budgetsDisabled.pagination.limit,
              searchDisabled
            )
          }
          onLimitChange={(limit) =>
            getBudgetsDisabled(
              budgetsDisabled.pagination.currentPage,
              limit,
              searchDisabled
            )
          }
        />
      </div>

      <DisableBudgetModal
        isOpen={!!modals.disable}
        onCancel={() => {
          setModals((state) => {
            return {
              ...state,
              disable: null,
            };
          });
        }}
        onConfirm={handleDisableBudget}
        onRequestClose={() => {
          setModals((state) => {
            return {
              ...state,
              disable: null,
            };
          });
        }}
      />

      <DeleteBudgetModal
        isOpen={!!modals.delete}
        onCancel={() => {
          setModals((state) => {
            return {
              ...state,
              delete: null,
            };
          });
        }}
        onConfirm={handleDeleteBudget}
      />

      <UpdateBudgetPriceModal isOpen={false} onCancel={() => null} />
    </Container>
  );
};
