import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CloudIcon } from '../../../../../../components/icons';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import RadioGroup from '../../../../../../components/radio-group';
import Textarea from '../../../../../../components/textarea';
import { Order, OrderFile } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { Status } from '../../../components/tag';
import { SentDate } from '../../../styles';
import {
  CancelOrder,
  Content,
  Description,
  Documents,
  Observations,
  Wrapper,
} from './styles';

type ConsultantProcessingProps = {
  order: Order;
};

type OrderFiles = {
  PersonType: OrderFile[];
  HouseDocumentProof: OrderFile[];
  BankDocument: OrderFile[];
  OtherDocuments: OrderFile[];
  AcceptanceTerm: OrderFile[];
};

type Form = {
  isDocumentationOk: boolean;
  description: string;
};

type OrderUpdated = {
  isDocumentationOk: boolean;
  orderCenterMessage?: string | null;
  documentationMessage?: string | null;
  status: Status;
  franchiseStatus: Status;
};

const schema = yup.object().shape(
  {
    isDocumentationOk: yup.boolean(),
    description: yup
      .string()
      .when('isDocumentationOk', (value) =>
        !value
          ? yup.string().required('Campo obrigatório')
          : yup.string().optional()
      ),
  },
  [['isDocumentationOk', 'description']]
);

export const ConsultantProcessing: React.FC<ConsultantProcessingProps> = (
  props
) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const files = useMemo(() => {
    return (
      order.files.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [...acc[file.type as keyof OrderFiles], file],
            };
          }

          return acc;
        },
        {
          PersonType: [],
          BankDocument: [],
          HouseDocumentProof: [],
          OtherDocuments: [],
          AcceptanceTerm: [],
        }
      ) ?? {
        PersonType: [],
        BankDocument: [],
        HouseDocumentProof: [],
        OtherDocuments: [],
        AcceptanceTerm: [],
      }
    );
  }, [order]);

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const {
    control,
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      isDocumentationOk: order.isDocumentationOk,
      description: '',
    },
    shouldUseNativeValidation: false,
  });

  const isDocumentationOk = watch('isDocumentationOk');

  const hasErrors = !!Object.entries(errors).length;

  const updateOrder = (orderUpdated: OrderUpdated) => {
    productsApi
      .put(`/orders/${order.id}`, orderUpdated)
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = (data: Form) => {
    if (isDocumentationOk) {
      const orderUpdated: OrderUpdated = {
        isDocumentationOk: true,
        orderCenterMessage: data.description.length ? data.description : null,
        documentationMessage: null,
        franchiseStatus: 'Pedido processado',
        status: 'Enviado para CP',
      };

      updateOrder(orderUpdated);

      return;
    }

    const orderUpdated: OrderUpdated = {
      isDocumentationOk: false,
      documentationMessage: data.description.length ? data.description : null,
      orderCenterMessage: null,
      status: 'Pedido incompleto',
      franchiseStatus: 'Pedido incompleto',
    };

    updateOrder(orderUpdated);
  };

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)} noValidate>
        <Observations>
          <div>
            <h1>Observações do pedido</h1>

            <p>{order.observations}</p>
          </div>

          <CancelOrder>
            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </CancelOrder>
        </Observations>

        <Documents>
          <div>
            <h1>Documentos enviados pelo vendedor</h1>

            <Wrapper>
              <ul>
                <label>Pessoa física ou jurídica</label>

                {files.PersonType.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Comprovante de residência</label>

                {files.HouseDocumentProof.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Documento do Banco</label>

                {files.BankDocument.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Termo de aceite assinado</label>

                {files.AcceptanceTerm.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Outros documentos</label>

                {files.OtherDocuments.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
          </div>
        </Documents>

        <Description>
          <Controller
            control={control}
            name="isDocumentationOk"
            render={({ field: { ref, onChange, value } }) => {
              return (
                <RadioGroup
                  ref={ref}
                  label="Está tudo certo com a documentação?"
                  options={[
                    {
                      label: 'Sim',
                      value: 'true',
                    },
                    {
                      label: 'Não',
                      value: 'false',
                    },
                  ]}
                  rowMode
                  onChange={async (event) => {
                    const value = JSON.parse(event.target.value);

                    onChange(value);

                    if (value) {
                      clearErrors('description');
                    }
                  }}
                  value={JSON.stringify(value)}
                />
              );
            }}
          />

          <Textarea
            label={
              isDocumentationOk
                ? 'Envia a mensagem para a Central de Pedidos'
                : 'Descreva o problema com a documentação'
            }
            placeholder="Digite aqui"
            resize="vertical"
            height="4.8rem"
            error={errors.description?.message}
            required={!isDocumentationOk}
            {...register('description')}
          />
        </Description>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
          <Button
            type="submit"
            text={
              isDocumentationOk ? 'Enviar para CP' : 'Enviar para o franqueado'
            }
            disabled={hasErrors}
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
