import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100%;

  padding: 1.5rem 0;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  background-color: #ffffff;

  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.15);

  margin: 0 -2rem;
  padding: 1.5rem 3rem;

  & > div {
    overflow: auto;

    margin: 0 -3rem;

    padding: 0 3rem;

    background-color: #ffffff;

    &::-webkit-scrollbar {
      width: 20rem;
      height: 0.5rem;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00299b;

      border-radius: 8px;
    }

    & > table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      & > thead {
        & > tr {
          & > th {
            text-align: left;

            white-space: nowrap;

            padding: 1rem 1.5rem;

            height: 3.5rem;

            border-bottom: #e0e0e0 solid 1px;

            color: #00299b;

            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
          }
        }
      }

      & > tbody {
        & > tr {
          & > td {
            padding: 1rem 1.5rem;

            height: 6.25rem;

            white-space: nowrap;

            color: #00299b;

            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;

            & > strong {
              display: flex;
              flex-direction: column;

              font-weight: 600;
              font-size: 0.875rem;

              color: #00299b;

              white-space: nowrap;

              & > span {
                font-weight: 500;
                font-size: 0.875rem;

                color: #4e6bb9;
              }
            }

            background-color: #fff;
          }
        }
      }
    }
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: 3rem;

  height: 4rem;

  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;

    letter-spacing: 0.01em;

    color: #00299b;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & > div > button.edit-values > svg {
    width: 1.25rem;
    height: 1.25rem;

    & > path {
      stroke-width: 4px;

      stroke: #ffffff;
    }
  }
`;

export const KitsTab = styled.div`
  display: flex;
  align-items: center;

  align-self: flex-end;
`;

export const TableFooter = styled.tfoot``;

export const Actions = styled.div`
  display: flex;

  align-items: center;
  gap: 0.75rem;

  & > button[data-tip='Visualizar'] {
    border: 1px solid #00299b;

    box-shadow: 0;

    & > svg {
      color: #00299b;
    }
  }
`;

export const ServicesSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;

  height: 100%;

  & > label {
    color: #27282b;
  }

  & textarea {
    outline: none;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;

  width: 100%;

  & > button > svg > path {
    stroke: #ffffff;
  }

  @media (max-width: 825px) {
    gap: 2.5rem;
    flex-direction: column;

    & > label {
      width: 100%;
    }

    & > div {
      margin-left: unset;
    }
  }
`;

export const ValuesData = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  white-space: nowrap;

  font-weight: 500;
  color: ${(props) => props.theme.mediumBlue};

  padding: 1rem 0 !important;

  & > span {
    font-size: 0.875rem;
    padding: 0 !important;
  }

  & > strong {
    color: ${(props) => props.theme.darkBlue};
  }

  & > span.hr {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.mediumBlue};
  }
`;
