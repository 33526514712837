import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ActionButton,
  Breadcrumb,
  Button,
  CoinsIcon,
  StepProgress,
} from '../../../../../components';
import Loading from '../../../../../components/loading';
import { KitDescriptionModal } from '../../../../../components/modal/kit-description';
import RadioGroup from '../../../../../components/radio-group';
import Textarea from '../../../../../components/textarea';
import { useBudgets } from '../../../../../contexts/budgets';
import productsApi from '../../../../../services/products';
import { ButtonGroup, PageHeader } from '../../../../styles';
import {
  Actions,
  Container,
  Content,
  ServicesContainer,
  ServicesSummaryWrapper,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';
import { formatQuantity } from '../../../../../utils/formatQuantity';
import { EditCustomProfitabilityModal } from '../../../../../components/modal/edit-custom-profitability';
import { BudgetItem } from '../../../../../contexts/budgets/types';
import InputPrice from '../../../../../components/input-price';
import useMediaQuery from '../../../../../hooks/mediaQuery';

type Form = {
  hideInstalationKit: boolean;
  bonusInsurance: boolean;
  monthlyIncome: string;
  observations: string;
};

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Personalizar kit', active: false, complete: true },
  { key: 4, title: 'Serviços', active: true, complete: false },
  { key: 5, title: 'Proposta', active: false, complete: false },
];

const schema = yup.object().shape(
  {
    hideInstalationKit: yup.boolean(),
    bonusInsurance: yup.boolean(),
    monthlyIncome: yup.string().required('Campo obrigatório'),
    observations: yup.string().optional(),
  },
  [['price', 'quantityPerYear']]
);

type Modals = {
  description: string;
  profitability: boolean;
};

const CustomKitServicesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Personalizar kit',
      link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`,
      active: false,
    },
    {
      id: 5,
      title: 'Serviços',
      link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/servicos/`,
      active: true,
    },
  ];

  const isTablet = useMediaQuery('(max-width: 825px)');

  const { budget, getProfitability, getBudget } = useBudgets();

  const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([]);

  const [modals, setModals] = useState<Modals>({
    description: '',
    profitability: false,
  });

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<Form>({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    defaultValues: {
      hideInstalationKit: false,
      bonusInsurance: false,
    },
  });

  const hasErrors = !!Object.values(errors).length;

  const updateBudgetStatus = async (budgetId: string) => {
    await productsApi.patch(`/budgets/${budgetId}`, {
      status: 'Completo',
    });
  };

  const createProposal = async (data: Form) => {
    const { hideInstalationKit, observations } = data;

    setLoading(true);

    try {
      await productsApi.patch(`/budgets/${id}/service-info`, {
        hideInstalationKit: !hideInstalationKit,
        observations: observations || undefined,
      });

      await updateBudgetStatus(id);

      history.replace(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/servicos/proposta/${
          hasEditPath ? 'editar' : ''
        }`
      );
    } catch (error) {
      toast.error('Não foi possível gerar sua proposta');
    } finally {
      setLoading(false);
    }
  };

  const openProfitabilityModal = async () => {
    await getProfitability();

    setModals((state) => {
      return {
        ...state,
        profitability: true,
      };
    });
  };

  const closeProfitabilityModal = async () => {
    setModals((state) => {
      return {
        ...state,
        profitability: false,
      };
    });

    await handleBudgetItems();
  };

  const deleteBudgetItem = async (budgetItemId: string) => {
    try {
      await productsApi.delete(`/budgets/${id}/${budgetItemId}/remove-item`);

      setBudgetItems((state) =>
        state.filter((item) => item.id !== budgetItemId)
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível remover o kit');
    }
  };

  const handleBudgetItems = async () => {
    const { items } = await getBudget(id);

    if (!items.length) {
      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`
      );

      return;
    }

    setBudgetItems(items);
  };

  useEffect(() => {
    handleBudgetItems();

    reset({
      hideInstalationKit: budget?.hideInstalationKit,
      observations: budget?.observations ?? '',
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Serviços</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content onSubmit={handleSubmit(createProposal)}>
        {!loading && (
          <TableContainer>
            <TableHeader>
              <h1>Kits Personalizados</h1>

              <div>
                <Button
                  type="button"
                  text="Editar valores"
                  icon={<CoinsIcon />}
                  onClick={openProfitabilityModal}
                  className="edit-values"
                />
              </div>
            </TableHeader>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Composição</th>
                    <th>Tipo</th>
                    <th>Marca</th>
                    <th>Kits</th>
                    <th>Módulos</th>
                    <th>Valores</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {budgetItems.map((item, i) => {
                    const { customItem } = item;

                    const kitsDescription =
                      customItem?.kits
                        .map((kit) => kit.product.description)
                        .join(', ') ?? '';

                    const quantity =
                      customItem?.kits.reduce(
                        (acc, kit) => acc + kit.quantity,
                        0
                      ) ?? 0;

                    const solarPlatesNumber =
                      customItem?.solarPlatesNumber ?? 0;

                    return (
                      <tr key={item.id}>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.type}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.supplier}</strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(quantity, 'Kit', 'Kits')}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(
                              solarPlatesNumber,
                              'módulo',
                              'módulos'
                            )}
                          </strong>
                        </td>
                        <td>
                          <ValuesData>
                            <span>Kit: {item.formatted.price}</span>
                            <span>Instalação: {item.formatted.income}</span>
                            <span className="hr" />
                            <strong>Total: {item.formatted.total}</strong>
                          </ValuesData>
                        </td>
                        <td>
                          <Actions>
                            <ActionButton
                              tooltip="Editar"
                              onClick={() => {
                                const filters = {
                                  fabricMaterial:
                                    customItem?.kits
                                      .map((kit) => kit.product.fabricMaterial)
                                      .join(',') ?? '',
                                  supplier: customItem?.supplier ?? '',
                                };

                                const queryParams = new URLSearchParams(
                                  filters
                                ).toString();

                                history.push(
                                  `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits/composicao/${customItem?.id}/editar?${queryParams}`
                                );
                              }}
                            >
                              <FiEdit />
                            </ActionButton>
                            <ActionButton
                              tooltip="Remover"
                              onClick={() => deleteBudgetItem(item.id)}
                            >
                              <FiTrash2 className="delete-icon" />
                            </ActionButton>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() =>
                                setModals((state) => ({
                                  ...state,
                                  description: kitsDescription,
                                }))
                              }
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            </div>
          </TableContainer>
        )}

        <ServicesSummaryWrapper>
          <ServicesContainer>
            <Controller
              name="hideInstalationKit"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <RadioGroup
                    ref={ref}
                    label="Mostrar valores separados?"
                    rowMode
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={(event) =>
                      onChange(JSON.parse(event.target.value))
                    }
                    value={JSON.stringify(value)}
                  />
                );
              }}
            />
            <Controller
              name="bonusInsurance"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <RadioGroup
                    ref={ref}
                    label="Seguro bonificado?"
                    rowMode
                    options={[
                      { label: 'Sim', value: 'true' },
                      { label: 'Não', value: 'false' },
                    ]}
                    onChange={(event) =>
                      onChange(JSON.parse(event.target.value))
                    }
                    value={JSON.stringify(value)}
                  />
                );
              }}
            />
            <InputPrice
              label="Renda mensal média"
              type="text"
              placeholder="R$ 0,00"
              style={{ width: isTablet ? '100%' : '16.125rem' }}
              {...register('monthlyIncome')}
              error={errors.monthlyIncome?.message}
            />
          </ServicesContainer>

          <Textarea
            label="Observações do orçamento"
            height="8rem"
            placeholder="Digite aqui as observações do orçamento"
            {...register('observations')}
          />
        </ServicesSummaryWrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`
              );
            }}
          />

          <Button type="submit" disabled={hasErrors} text="Gerar proposta" />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={!!modals.description}
        description={modals.description as string}
        onCancel={() => {
          setModals((state) => {
            return {
              ...state,
              description: '',
            };
          });
        }}
      />

      <EditCustomProfitabilityModal
        isOpen={modals.profitability}
        onCancel={closeProfitabilityModal}
        onRequestClose={closeProfitabilityModal}
      />
    </Container>
  );
};

export default CustomKitServicesPage;
